
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiCompanyGetDetail,
         apiCompanyDetailUpdate,
         apiCompanyCreate } from '@/services/tpsApi/tpsApiCompany';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import ICompanyDetail from "@/services/tpsApi/models/company/ICompanyDetail";
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
      WarningModal
  },
  emits: [
      "hide-form"
  ]
})

export default class CompanyForm extends Vue {
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: ICompanyDetail = {} as any;
    formData: ICompanyDetail = {} as any;
    actionType: string = "";
    companyID: number = -1;

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }

    async initForm(pActionType, pCompanyID) {
        this.actionType = pActionType;
        this.companyID = pCompanyID;
        if(pCompanyID) {
            this.formData = Object.assign({}, await apiCompanyGetDetail(pCompanyID));
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            //Default data
            this.formData["invalid"] = 0;
        }
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave) {
            //Set default modified_time & modified_user
            let dt = new Date(); 
            this.formData["modified_time"] = `${
                (dt.getMonth()+1).toString().padStart(2, '0')}-${
                dt.getDate().toString().padStart(2, '0')}-${
                dt.getFullYear().toString().padStart(4, '0')} ${
                dt.getHours().toString().padStart(2, '0')}:${
                dt.getMinutes().toString().padStart(2, '0')}:${
                dt.getSeconds().toString().padStart(2, '0')}`;
            this.formData["modified_user"] = this.$store.getters.currentUser;
            if(this.actionType == "update") {
                let res = await apiCompanyDetailUpdate({id: this.companyID, data: arrangeUpdateData(this.formData,this.originalData)});
                if (res.msg === ("ok")) this.hideForm(true);
                else {
                    this.hideForm(false);
                    alert(res.msg);
                }
            }      
            else if(this.actionType == "create") {
                let res = await apiCompanyCreate(this.formData);
                if (res.msg === ("ok")) this.hideForm(true);
                else {
                    this.hideForm(false);
                    alert(res.msg);
                }
            }    
        }
        else if(pSave == false) {
            this.hideForm(false);
        }
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
