import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_ActionMenu = _resolveComponent("ActionMenu")!
  const _component_CompanyForm = _resolveComponent("CompanyForm")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Alert, { ref: "alert" }, null, 512),
    _createVNode(_component_ActionMenu, {
      onForm: _ctx.showForm,
      onAlert: _ctx.doAlert,
      onExport: _ctx.download,
      onSearch: _ctx.doSearch,
      table: "company"
    }, null, 8, ["onForm", "onAlert", "onExport", "onSearch"]),
    _createVNode(_component_CompanyForm, {
      "show-form": _ctx.formVisible,
      ref: "form",
      onHideForm: _ctx.hideForm
    }, null, 8, ["show-form", "onHideForm"]),
    _createVNode(_component_Table, {
      tTitle: "公司資訊",
      tHead: _ctx.TABLE_HEAD,
      tData: _ctx.companyList,
      tEdit: "true",
      tFilter: "true",
      tPageNo: _ctx.pageNo['公司資訊'],
      onForm: _ctx.showForm
    }, null, 8, ["tHead", "tData", "tPageNo", "onForm"]),
    _createVNode(_component_Pagination, {
      pTotalNum: _ctx.pageTotalNum,
      pCurrentPage: _ctx.currentPage,
      pLimit: _ctx.currentLimit,
      onChangePage: _ctx.refreshTabel,
      onChangeLimit: _ctx.refreshTabel
    }, null, 8, ["pTotalNum", "pCurrentPage", "pLimit", "onChangePage", "onChangeLimit"])
  ]))
}